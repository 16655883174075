<template>
  <div class="container">
    <div class="content">
      <div class="table1">
        <div class="title">
          <div class="title-top">
            <img src="../assets/changpin/box.png"
                 class="img-icon"
                 alt="">
            <h1 class="title-h1">PRODUCTS</h1>
          </div>
          <div class="title-bottom"
               v-if="$store.state.isZH">
            产品中心
          </div>
        </div>
        <div class="content-table">
          <div class="left">
            <div class="left-title"
                 :class="$store.state.isZH ? '' : 'enWidth'">{{ $t('product.Veterinary') }}</div>
            <div class="left-icon"><img src="../assets/changpin/icon.png"
                   class="title-icon"
                   alt=""></div>
          </div>

          <!-- 表格 -->
      <div class="right">
        <div class="table-title">
          <el-row type="flex"
                  align="middle">
            <el-col :span="5"
                    v-if="$store.state.isZH">
              产品名称
            </el-col>
            <el-col :span="13"
                    :class="$store.state.isZH ? '' : 'ENBorder' "
                    class="ENName">
              Product Name
            </el-col>
            <el-col :span="6"
                    class="cas">
              CAS No.
            </el-col>
          </el-row>
        </div>

        <div class="table-content">
          <div v-for="item in animal"
               :key="item.enName">
            <div class="table-row">
              <el-row type="flex"
                      align="middle">
                <el-col :span="5"
                        v-if="$store.state.isZH">
                  {{ item.zhName }}
                </el-col>
                <el-col :span="13"
                        :class="$store.state.isZH ? '' : 'ENBorder' "
                        class="neirong">
                  {{item.enName}}
                </el-col>
                <el-col :span="6"
                        class="cas">
                  {{ item.cas }}
                </el-col>
              </el-row>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="table1">
    <div class="content-table">
      <div class="left">
        <div class="left-title">{{ $t('product.Human') }}</div>
        <div class="left-icon"><img src="../assets/changpin/user.png"
               class="title-icon"
               alt=""></div>
      </div>
      <div class="right">
        <div class="table-title">
          <el-row type="flex"
                  align="middle">
            <el-col :span="5"
                    v-if="$store.state.isZH">
              产品名称
            </el-col>
            <el-col :span="13"
                    class="ENName"
                    :class="$store.state.isZH ? '' : 'ENBorder' ">
              Product Name
            </el-col>
            <el-col :span="6"
                    class="cas">
              CAS No.
            </el-col>
          </el-row>
        </div>

        <div class="table-content">
          <div v-for="item in peopleUse"
               :key="item.enName">
            <div class="table-row">
              <el-row type="flex"
                      align="middle">
                <el-col :span="5"
                        v-if="$store.state.isZH">
                  {{ item.zhName }}
                </el-col>
                <el-col :span="13"
                        :class="$store.state.isZH ? '' : 'ENBorder' "
                        class="neirong">
                  {{item.enName}}
                </el-col>
                <el-col :span="6"
                        class="cas">
                  {{ item.cas }}
                </el-col>
              </el-row>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Product',
  data () {
    return {
      animal: [
        {
          zhName: '绒促性素',
          enName: 'Chorionic Gonadotrophin (HCG)',
          cas: '9002-61-3'
        },
        {
          zhName: '血促性素',
          enName: 'Serem Gonadotrophin (PMSG)',
          cas: '9002-70-4'
        },
        {
          zhName: '氯前列醇钠',
          enName: 'Cloprostenol Sodium',
          cas: '55028-72-3'
        },
        {
          zhName: 'D-氯前列醇钠',
          enName: 'D-Cloprostenol Sodium',
          cas: '62561-03-9'
        },
        {
          zhName: '四烯雌酮/烯丙孕素',
          enName: 'Altrenogest',
          cas: '850-52-2'
        },
        {
          zhName: '地诺前列素氨丁三醇',
          enName: 'Dinoprost Trometamol/Prostaglandin F2α Tromethamine',
          cas: '38562-01-5'
        },
        {
          zhName: '地诺前列素',
          enName: 'Dinoprost/Prostaglandin F2α',
          cas: '551-11-1'
        },
        {
          zhName: '匹莫苯丹',
          enName: 'Pimobendan',
          cas: '74150-27-9'
        },
        {
          zhName: '曲洛司坦',
          enName: 'Trilostane',
          cas: '13647-35-3'
        },
        {
          zhName: '盐酸地那维林',
          enName: 'Denaverine Hydrochloride',
          cas: '3321-06-0'
        },
        {
          zhName: '缩宫素',
          enName: 'Oxytocin',
          cas: '50-56-6'
        },
        {
          zhName: '卡贝缩宫素',
          enName: 'Carbetocin',
          cas: '37025-55-1'
        },
        {
          zhName: '醋酸丙氨瑞林/醋酸阿拉瑞林',
          enName: 'Alarelin Acetate',
          cas: '79561-22-1'
        },
        {
          zhName: '醋酸戈那瑞林',
          enName: 'Gonadorelin Acetate',
          cas: '34973-08-5'
        },
        {
          zhName: '醋酸曲普瑞林',
          enName: 'Triptorelin Acetate',
          cas: '57773-63-4'
        },
        {
          zhName: '醋酸兰瑞肽',
          enName: 'Lecirelin Acetate',
          cas: '61012-19-9'
        },
        {
          zhName: '醋酸德舍瑞林',
          enName: 'Deslorelin Acetate',
          cas: '82318-06-7'
        },
        {
          zhName: '醋酸布舍瑞林',
          enName: 'Buserelin Acetate',
          cas: '68630-75-1'
        },
        {
          zhName: '醋酸夫替瑞林',
          enName: 'Fertirelin Acetate',
          cas: '66002-66-2'
        },
        {
          zhName: '马罗匹坦',
          enName: 'Maropitant Citrate',
          cas: '147116-67-4'
        },
        {
          zhName: '甲硫酸新斯的明',
          enName: 'Neostigmine Methyl Sulfate',
          cas: '51-60-5'
        }
      ],
      peopleUse: [
        {
          zhName: '绒促性素',
          enName: 'Human Chorionic Gonadotrophin (HCG)',
          cas: '9002-61-3'
        },
        {
          zhName: '尿促性素',
          enName: 'Human Menopausal Gonadotrophin (HMG)',
          cas: '61489-71-2'
        },
        {
          zhName: '尿促卵泡素',
          enName: 'Urofollitropin (FSH)',
          cas: '97048-13-0'
        },
        {
          zhName: '尿激酶',
          enName: 'Urokinase (UK)',
          cas: '9039-53-6'
        },
        {
          zhName: '乌司他丁',
          enName: 'Ulinastatin (UTI)',
          cas: '86449-31-6'
        },
        {
          zhName: '高纯尿促性素',
          enName: 'Highly Purified Menotropins (HP-HMG)',
          cas: '61489-71-2'
        },
        {
          zhName: '高纯尿促卵泡素',
          enName: 'Highly Purified Urofollitropin (HP-FSH)',
          cas: '97048-13-0'
        },
        {
          zhName: '科里内酯二醇',
          enName: 'Corey Lactone Diol',
          cas: '54423-47-1'
        },
        {
          zhName: '左旋科里内酯二醇',
          enName: '(-)-Corey Lactone Diol',
          cas: '32233-40-2'
        },
        {
          zhName: '苯甲酰科里内酯',
          enName: '(-)-Corey Lactone Benzoate',
          cas: '39746-00-4'
        },
        {
          zhName: '缩宫素',
          enName: 'Oxytocin',
          cas: '50-56-6'
        },
        {
          zhName: '卡贝缩宫素',
          enName: 'Carbetocin',
          cas: '37025-55-1'
        },
        {
          zhName: '醋酸亮丙瑞林',
          enName: 'Leuprorelin Acetate',
          cas: '74381-53-6'
        },
        {
          zhName: '醋酸丙氨瑞林/醋酸阿拉瑞林',
          enName: 'Alarelin Acetate',
          cas: '79561-22-1'
        },
        {
          zhName: '醋酸戈那瑞林',
          enName: 'Gonadorelin Acetate',
          cas: '34973-08-5'
        },
        {
          zhName: '醋酸曲普瑞林',
          enName: 'Triptorelin Acetate',
          cas: '57773-63-4'
        },
        {
          zhName: '醋酸西曲瑞克',
          enName: 'Cetrorelix Acetate',
          cas: '120287-85-6'
        },
        {
          zhName: '阿维巴坦钠',
          enName: 'Avibactam Sodium',
          cas: '1192491-61-4'
        },
        {
          zhName: '阿维巴坦钾',
          enName: 'Avibactam Potassium',
          cas: 'N/A'
        },
        {
          zhName: '拉坦前列素',
          enName: 'Latanoprost',
          cas: '130209-82-4'
        },
        {
          zhName: '胸腺肽α1/胸腺法新',
          enName: 'Thymosin Alpha 1',
          cas: '62304-98-7'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.enWidth {
  width: 190px;
}
.container {
  // padding: 0 12%;
  .content {
    width: 100%;
    height: 100%;
    padding: 22% 10% 0;
    background: url("../assets/changpin/bgc.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .table1 {
      padding-bottom: 10%;
      box-sizing: border-box;
      .title {
        color: #fff;
        padding-bottom: 8%;
        box-sizing: border-box;
        .img-icon {
          height: 65px;
          padding-right: 6%;
        }
        .title-top {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .title-h1 {
          font-size: 70px;
        }
        .title-bottom {
          display: flex;
          justify-content: flex-end;
          font-size: 47px;
          font-weight: bold;
        }
      }
      .content-table {
        display: flex;
        justify-content: space-between;
        .left {
          width: 18%;
          .left-title {
            font-size: 31px;
            color: #fff;
            padding-bottom: 20px;
          }
        }
        .right {
          font-size: 18px;
          width: 77%;
          box-sizing: border-box;
          border-top: 2px solid #fff;
          .ENBorder {
            border-left: 0 !important;
          }
          .table-title {
            color: #fff;
            font-size: 21px;
            font-weight: 600;
            height: 75px;
            border-bottom: 10px solid #fff;
            box-sizing: border-box;
            .el-row {
              width: 100%;
              height: 100%;
            }

            .ENName {
              border-left: 1px dashed #fff;
              border-right: 1px dashed #fff;
              padding-left: 2%;
              box-sizing: border-box;
              height: 70px;
              line-height: 70px;
            }
          }
          .cas {
            padding-left: 2%;
          }
          .table-content {
            color: #fff;
            .table-row {
              .el-row {
                border-bottom: 1px solid #fff;
                height: 50px;
              }
            }
            .neirong {
              display: flex;
              align-items: center;
              height: 50px;
              font-size: 18px;
              border-left: 1px dashed #fff;
              border-right: 1px dashed #fff;
              box-sizing: border-box;
              padding-left: 2%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .container {
    .content {
      padding: 22% 3%;
      .table1 {
        .title {
          .title-top {
            .title-h1 {
              font-size: 30px !important;
            }
            .img-icon {
              width: 30px !important;
            }
          }
          // .title-bottom {
          // }
        }
        .content-table {
          .right {
            font-size: 14px;
            .table-title {
              .el-row {
                font-size: 18px;
              }
            }
            .table-content {
              .table-row {
                .el-row {
                  height: 50px;
                }
              }
              .neirong {
                font-size: 12px;
                height: 50px;
              }
              .el-row {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 1200px) {
  .container {
    .content {
      .table1 {
        .title {
          .title-top {
            .title-h1 {
              font-size: 55px;
            }
            .img-icon {
              width: 50px;
              height: 30px;
            }
          }
          .title-bottom {
            font-size: 23px;
          }
        }
        .content-table {
          flex-direction: column;
          .right {
            width: 100%;
          }
        }
      }
    }
  }

  .container .content .table1 .content-table .left {
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 10%;
    .left-title {
      font-size: 22px;
      padding-bottom: 0;
      padding-right: 10px;
    }
    .title-icon {
      width: 30px;
      height: 30px;
    }
  }
}
</style>